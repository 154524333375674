import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class DosificacionService {

  async getDosificacionAll() {
    const Dosificacion = await fetchWrapper.get(`${ruta}/dosificacion`);
    return Dosificacion;
  }

  async sendDosificacionNew(datos) {
    const nuevomedico = await fetchWrapper.post(`${ruta}/dosificacion`, datos);
    return nuevomedico;
  }

  async updateDosificacion(datos) {
    const medicoActualizado = await fetchWrapper.put(
      `${ruta}/dosificacion/` + datos.id,
      datos
    );
    return medicoActualizado;
  }

  async deleteDosificacion(id) {
    return await fetchWrapper.delete(`${ruta}/dosificacion/${id}`);
  }
}