<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="Dosificación Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for=""><strong>LLAVE: </strong></label>
          <Textarea
            v-model="data_dosificacion.llave"
            :rows="2"
            placeholder="Ingrese la llave de la dosificación"
          />
          <small class="p-invalid" v-if="errors.llave">{{
            errors.llave[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-8">
          <label for="autorizacion"
            ><strong>AUTORIZACI&Oacute;N: </strong
            ><span class="p-invalid">*</span></label
          >
          <InputText
            id="autorizacion"
            placeholder="Ingrese el número de autorización"
            v-model.trim="data_dosificacion.autorizacion"
            required="true"
          />
          <small class="p-invalid" v-if="errors.autorizacion">{{
            errors.autorizacion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="nrofactura"
            ><strong>N&Uacute;MERO FACTURA: </strong
            ><span class="p-invalid">*</span></label
          >
          <InputText
            id="nrofactura"
            placeholder="Ingrese el número de factura"
            v-model.trim="data_dosificacion.nrofactura"
            required="true"
          />
          <small class="p-invalid" v-if="errors.nrofactura">{{
            errors.nrofactura[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="fechareg"
            ><strong>FECHA REGISTRO: </strong
            ><span class="p-invalid">*</span></label
          >
          <Calendar 
          id="fechareg" 
          v-model="data_dosificacion.fechareg"
          dateFormat="dd/mm/yy"
          :showIcon="true"
          />
          <small class="p-invalid" v-if="errors.fechareg">{{
            errors.fechareg[0]
          }}</small>
        </div>

        <div class="field col-12 md:col-4">
          <label for="fechalimite"
            ><strong>FECHA LIMITE: </strong
            ><span class="p-invalid">*</span></label
          >
          <Calendar
            id="fechalimite"
            dateFormat="dd/mm/yy"
            v-model="data_dosificacion.fechalimite"
            :showIcon="true"
          />
          <small class="p-invalid" v-if="errors.fechalimite">{{
            errors.fechalimite[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="estado"
            ><strong>Estado: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_dosificacion.estado"
            :disabled="!data_dosificacion.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>Campos Requeridos: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="saveDosificacion"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import DosificacionService from "@/service/DosificacionService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dosificacion: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  dosificacionService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_dosificacion: this.dosificacion,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  created() {
    this.dosificacionService = new DosificacionService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
    saveDosificacion() {
      this.errors = {};
      this.enviado = true;
      //editar dosificacion
      if (this.dosificacion.id) {
        let dosificacion_enviar = {
          ...this.data_dosificacion,
          estado: this.data_dosificacion.estado.value,
        };
        this.dosificacionService.updateDosificacion(dosificacion_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_dosificacion;
        this.data_dosificacion = {
          ...this.data_dosificacion,
          estado: this.data_dosificacion.estado.value,
        };
        this.dosificacionService.sendDosificacionNew(this.data_dosificacion).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_dosificacion = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    dosificacion(val) {
      this.data_dosificacion = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
